import api from '../library/axios';
import ls from '../library/localStorage';

export default {
  getList(is_workspace = false) {
    return api({
      method: 'get',
      url: '/api/backoffice/permissions',
      params: {
        position: is_workspace,
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
};
