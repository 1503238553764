<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a @click.prevent="selectCompany"  class="sidebar-brand" style="cursor: pointer;">
        <img src="@/assets/images/wappin-logo-white.png" height="30" />
      </a>
      <div
        class="sidebar-toggler active"
        @click="toggleSidebar"
        ref="sidebarToggler">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <ul class="nav">
        <SidebarCategory title="Main" />
        <SidebarItem
          v-for="(menuItem, i) in menuList"
          :key="i"
          :data="menuItem"
        />
        <!-- <SidebarItem :data="{ to: '#1', title: 'Lorem', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#2', title: 'Ipsum', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#3', title: 'Dolor', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#4', title: 'Sit Amet', icon: 'tag' }" /> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import cookie from '../../library/cookie';
import menuAPI from '../../api/permissionBackoffices';

const menu = (to, title, icon, child = [], el_icon = '', id, pic_only = false, division_admin_only = false) => ({
  to,
  title,
  icon,
  child,
  el_icon,
  pic_only,
  division_admin_only,
  id,
});

export default {
  name: 'Sidebar',
  metaInfo: {
    bodyAttrs: {
      class: ['sidebar-dark'],
    },
  },
  data() {
    return {
      /* eslint-disable no-unneeded-ternary */
      isFolded: false,
      menuList: [],
      perfectScroll: [],
      labelList: [],
    };
  },
  mounted() {
    if (this.$store.state.auth.userProfile.company_id === '96y5cKiZbwH7myjS_ZEuA') { // digitalent
      this.menuList = this.menuList.filter((v) => !['/contacts'].includes(v.to));
    }
    this.setSidebar();
    if (document.querySelectorAll('.sidebar .sidebar-body').length) {
      this.perfectScroll.push(new PerfectScrollbar('.sidebar-body'));
    }
    if (document.querySelectorAll('.content-nav-wrapper').length) {
      this.perfectScroll.push(new PerfectScrollbar('.content-nav-wrapper'));
    }
    this.getMenuList();
  },
  computed: {
    isLargeScreen: () => window.document.body.clientWidth > 991,
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    activeWorkspaceId() {
      return this.$store.state.workspace.activeWorkspace._id;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    companyId() {
      return this.$store.state.backOffice.activeCompany;
    },
  },
  watch: {
    isLargeScreen() {
      this.setSidebar();
    },
  },
  methods: {
    mouseEnter() {
      if (document.body.classList.contains('sidebar-folded')) {
        document.body.classList.add('open-sidebar-folded');
      }
    },
    mouseLeave() {
      if (document.body.classList.contains('sidebar-folded')) {
        document.body.classList.remove('open-sidebar-folded');
      }
    },
    setSidebar() {
      if (cookie.get('sidebar-folded') === 'true' && this.isLargeScreen) {
        setTimeout(() => {
          this.toggleSidebar(false);
        }, 200);
      }
    },
    toggleSidebar(needToSetCookie = true) {
      document.body.classList.toggle(this.isLargeScreen ? 'sidebar-folded' : 'sidebar-open');
      this.$refs.sidebarToggler.classList.toggle('not-active');
      this.$refs.sidebarToggler.classList.toggle('active');
      this.isFolded = !this.isFolded;
      if (needToSetCookie && this.isLargeScreen) cookie.set('sidebar-folded', this.isFolded);
    },
    selectCompany() {
      this.$store.dispatch('backOffice/clearActiveCompany')
        .then(() => {
          this.$router.push('/company');
        });
    },
    async getMenuList() {
      await menuAPI.getList()
        .then(async (res) => {
          this.menuList = res.data.map((v) => {
            const item = menu(v.url_path, v.name, v.icon, [], v.custom_icon, true);
            return item;
          });
        })
        .catch(() => {});
    },
  },
  components: {
    SidebarCategory: () => import('./SidebarCategory.vue'),
    SidebarItem: () => import('./SidebarItem.vue'),
  },
};
</script>
